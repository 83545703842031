import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from "../../environments/environment";
import {Md5} from 'ts-md5/dist/md5';
import { DatePipe } from '@angular/common';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class AdsService {

  constructor(
    private http: HttpClient,
    private datePipe: DatePipe,
    private loginService: LoginService
  ) {}

  buildToken(){
    let myDate = new Date();
    let current_date = this.datePipe.transform(myDate, 'ddMMyyyy');
    return Md5.hashStr('CHOLLOS' + current_date);
  }

  // GET: Construir la llamada a la API
  getQuery(query: string) {
    const url = environment.api;
    const headers = new HttpHeaders({
        "Content-Type": "application/json",
    });

    return this.http.get(url + query, { headers });
  }

  // POST: Construir la llamada a la API
  postQuery(query: string, params = {}) {
    const url = environment.api;
    const headers = new HttpHeaders({
        "Content-Type": "application/json",
    });

    params['_token'] = this.buildToken();

    return this.http.post(url + query, params, { headers });
  }

  // Obtener listado de categorias + anuncios destacados para la home
  getHomeAds(){
    let params = {
      'poblacion_id': this.loginService.getCity()
    };
    return this.postQuery('get_home_ads', params);
  }

  // Obtener listado de categorias + anuncios destacados para la home
  getListAds(params = {}){
    params['poblacion_id'] = this.loginService.getCity();
    return this.postQuery('get_list_ads', params);
  }

  // Obtener datos de un anuncio
  getDataAd(id){
    let params = {};
    let user = this.loginService.getUserSession();

    // Enviar el id del usuario logueado (puede o no estar logueado)
    if(user){
      params = {
        'user_id': user.id
      };
    }

    return this.postQuery('get_ad/' + id, params);
  }

  // Obtener datos de un anuncio
  searchAds(params){
    return this.postQuery('search', params);
  }

  // Obtener listado de ciudades de una provincia
  getListCities(province_id){
    return this.getQuery('get_cities/' + province_id);
  }
}
