import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from '../api/login.service';
import { FunctionsService } from '../services/functions.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanLoad {

  constructor(
    private loginService: LoginService,
    private functionsService: FunctionsService
  ){}

  canLoad(): Observable<boolean> | Promise<boolean> | boolean {
    if(this.loginService.getToken()){
      // El usuario ya esta logueado
      this.loginService.getUser()
        .subscribe(
          (response) => {
            if(response['success']){
              // Actualizar datos del usuario al entrar de nuevo a la app
              this.loginService.setUserData(response['data']);
              this.functionsService.goToHome();
              return false;
            }else{
              return true;
            }
          },
          (error) => {
            // error.message
            console.log(error);
            this.functionsService.goToLogin();
            return true;
          }
        );
    }else{
      // El usuario no está logueado
      return true;
    }
  }
}
