import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LanguageService {

    constructor(
        private translate: TranslateService
    ) { }

    setInitialAppLanguage() {
        const language = this.translate.getBrowserLang();

        if (language) {
            this.translate.use(language);
            this.setLanguage(language);
        }
    }

    setLanguage(language){
        localStorage.setItem('language', language);
    }

    getLanguage(){
        let language = localStorage.getItem('language');
        if(language){
            return language;
        }else{
            language = 'es'; // Por defecto
            this.setLanguage(language);
            return language;
        }
    }
}
