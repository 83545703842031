import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FunctionsService } from '../../services/functions.service';
import { AdminService } from '../../api/admin.service';
import { AdsService } from '../../api/ads.service';
import { AlertController } from '@ionic/angular';

@Component({
  selector: 'app-form-business-address',
  templateUrl: './form-business-address.component.html',
  styleUrls: ['./form-business-address.component.scss'],
})
export class FormBusinessAddressComponent implements OnInit {

  @Input() business_address_id_selected;
  @Input() config_addresses = false;
  @Output() returnAddressId = new EventEmitter();

  public businessAddressForm: FormGroup;

  show_form = false;
  business_address = [];
  provinces = [];
  cities = [];

  constructor(
    private formBuilder: FormBuilder,
    public functionsService: FunctionsService,
    public adminService:AdminService,
    public adsService: AdsService,
    public alertController: AlertController
  ) { }

  ngOnInit() {
    this.getData();
    this.buildForm();
  }

  getData(){
    this.functionsService.showLoader();

    // Cargar datos del formulario
    this.adminService.buildFormBusinessAddress()
    .subscribe(
      (response) => {
        if(response['success']){
          console.log(response);

          this.business_address = response['data']['business_address'];
          this.provinces = response['data']['provinces'];

          this.functionsService.hideLoader();
        }else{
          this.functionsService.hideLoader();
        }
      },
      (error) => {
        this.functionsService.hideLoader();
        console.log(error);
      }
    );
  }

  buildForm(){

    // Validaciones del formulario
    this.businessAddressForm = this.formBuilder.group({
      'name': [null, Validators.compose([
        Validators.required,
        Validators.maxLength(50),
      ])],
      'address': [null, Validators.compose([
        Validators.required,
        Validators.maxLength(250),
      ])],
      'postal_code': [null, Validators.compose([
        Validators.required,
        Validators.maxLength(250),
      ])],
      'province_id': [null, Validators.compose([
        Validators.required
      ])],
      'city_id': [null, Validators.compose([
        Validators.required
      ])],
    });

    // Cargar datos
    //this.setDataForm();
  }

  changeProvince(event){
    console.log(event.detail.value);
    let province_id = event.detail.value;
    this.loadCities(province_id);
  }

  loadCities(province_id){
    this.functionsService.showLoader();

    // Cargar datos del formulario
    this.adsService.getListCities(province_id)
    .subscribe(
      (response) => {
        this.functionsService.hideLoader();
        if(response['success']){
          console.log(response);
          this.cities = response['data']['cities'];
        }
      },
      (error) => {
        this.functionsService.hideLoader();
        console.log(error);
      }
    );
  }

  save(){
    if(this.businessAddressForm.valid){
      let data = {
        'address': this.businessAddressForm.value,
      };
      this.functionsService.showLoader();
      this.adminService.createUpdateBusinessAddress(data)
      .subscribe(
        (response) => {
          this.functionsService.hideLoader();
          if(response['success']){
            this.business_address = response['data']['business_address'];
            this.cities = [];
            this.show_form = false;
          }else{
            this.functionsService.showDangerToast(response['data']['error_message']);
          }
        },
        (error) => {
          this.functionsService.hideLoader();
          this.functionsService.showDangerToast(error);
        }
      );
    }else{
      this.functionsService.showDangerToast('Completa los datos obligatorios');
    }
  }

  radioGroupChange(event){
    this.business_address_id_selected = event.detail.value;
  }

  selectAddress(){
    let address_id_selected = this.business_address_id_selected;

    // Enviar a la vista padre el objeto de la dirección seleccionada
    this.returnAddressId.emit(
      this.business_address.find(address => address['id'] === address_id_selected)
    );
  }

  showForm(){
    this.show_form = !this.show_form;
  }

  async presentAlertRemoveAddress(address_id) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: '¿Eliminar dirección?',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {}
        }, {
          text: 'Si',
          handler: () => {
            this.removeAddress(address_id);
          }
        }
      ]
    });

    await alert.present();
  }

  removeAddress(address_id){
    let data = {
      'address_id': address_id,
    };
    this.functionsService.showLoader();
    this.adminService.deleteBusinessAddress(data)
    .subscribe(
      (response) => {
        this.functionsService.hideLoader();
        console.log(response);
        if(response['success']){
          this.business_address = response['data']['business_address'];
          this.functionsService.showToast(response['data']['message']);
        }else{
          this.functionsService.showDangerToast(response['data']['error_message']);
        }
      },
      (error) => {
        this.functionsService.hideLoader();
        this.functionsService.showDangerToast(error);
      }
    );
  }
}
