import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { NewAdPageRoutingModule } from './new-ad-routing.module';

import { NewAdPage } from './new-ad.page';

import { defineLordIconElement } from 'lord-icon-element';
import lottie from 'lottie-web';
import { ComponentsModule } from '../../../components/components.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    NewAdPageRoutingModule,
    ComponentsModule
  ],
  declarations: [NewAdPage],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class NewAdPageModule {
  constructor() {
    defineLordIconElement(lottie.loadAnimation);
  }
}
