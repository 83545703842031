import { Component, OnInit, Input, Renderer2, ElementRef, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Plugins } from '@capacitor/core';

import { google } from 'google-maps';
// import {Marker} from '@ionic-native/google-maps';
import {MarketIcon} from '../../interfaces/marketicon';
declare var google: google;

import { Network } from '@capacitor/network';
import { Geolocation} from '@capacitor/geolocation';
import {LoadingController, NavController} from '@ionic/angular';

@Component({
  selector: 'google-map',
  templateUrl: './googlemaps.component.html',
  styleUrls: ['./googlemaps.component.scss']
})
export class GooglemapsComponent implements OnInit {
  @Input('apiKey') apiKey: string;
  @Input('items') items: any[];

  infoWindow = null;
  public map: any;
  public currentLocation: any;
  public markers: any[] = [];
  public mapsLoaded: boolean = false;
  private networkHandler = null;

  public infoWindows: any[] = [];

  constructor(private renderer: Renderer2, private element: ElementRef, @Inject(DOCUMENT) private _document,
              public navCtrl: NavController,
              public loadingCtrl: LoadingController) {
    this.infoWindow = new google.maps.InfoWindow();

  }

  async ngOnInit(){

    // Mostrar cargando
    const loader = await this.loadingCtrl.create();

    this.init().then((res) => {
      console.log("Google Maps ready.");
      loader.dismiss().then(async l => {}); // Ocultar cargando

    }, (err) => {
      console.log(err);
      loader.dismiss().then(async l => {}); // Ocultar cargando

    });

  }

  private init(): Promise<any> {

    console.log("inicio ojo");

    this.markers = this.items;

    return new Promise((resolve, reject) => {

      this.loadSDK().then((res) => {

        this.initMap().then((res) => {
          resolve(true);
        }, (err) => {
          reject(err);
        });

      }, (err) => {
        reject(err);

      });

    });

  }

  private loadSDK(): Promise<any> {

    console.log("Loading Google Maps SDK");

    return new Promise((resolve, reject) => {

      if(!this.mapsLoaded){

        Network.getStatus().then((status) => {

          if(status.connected){

            this.injectSDK().then((res) => {
              resolve(true);
            }, (err) => {
              reject(err);
            });

          } else {

            if(this.networkHandler == null){

              this.networkHandler = Network.addListener('networkStatusChange', (status) => {

                if(status.connected){

                  this.networkHandler.remove();

                  this.init().then((res) => {
                    console.log("Google Maps ready.")
                  }, (err) => {
                    console.log(err);
                  });

                }

              });

            }

            reject('Not online');
          }

        }, (err) => {

          // NOTE: navigator.onLine temporarily required until Network plugin has web implementation
          if(navigator.onLine){

            this.injectSDK().then((res) => {
              resolve(true);
            }, (err) => {
              reject(err);
            });

          } else {
            reject('Not online');
          }

        });

      } else {
        reject('SDK already loaded');
      }


    });


  }

  private injectSDK(): Promise<any> {

    return new Promise((resolve, reject) => {

      window['mapInit'] = () => {
        this.mapsLoaded = true;
        resolve(true);
      }

      let script = this.renderer.createElement('script');
      script.id = 'google-map';

      if(this.apiKey){
        script.src = 'https://maps.googleapis.com/maps/api/js?key=' + this.apiKey + '&callback=mapInit';
      } else {
        script.src = 'https://maps.googleapis.com/maps/api/js?callback=mapInit';
      }

      this.renderer.appendChild(this._document.body, script);

    });

  }

  private initMap(): Promise<any> {

    return new Promise((resolve, reject) => {

      Geolocation.getCurrentPosition().then((position) => {

        console.log(position);
        // Almacenar posición actual
        this.currentLocation = position;

        let latLng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);

        let mapOptions = {
          center: latLng,
          zoom: 15,
          mapTypeId: google.maps.MapTypeId.ROADMAP
        };

        this.map = new google.maps.Map(this.element.nativeElement, mapOptions);

        console.log("markers");
        console.log(this.markers);
        this.showMarkers();

        this.addMarker(position.coords.latitude,
            position.coords.longitude,
            google.maps.Animation.DROP,
            0,
            '',
            '',
            '',
            '',
            '',
            '',
            true);

        resolve(true);

      }, (err) => {

        // NOTE: navigator.onLine temporarily required until Network plugin has web implementation
        if(navigator.onLine){

          this.injectSDK().then((res) => {
            resolve(true);
          }, (err) => {
            reject(err);
          });

        } else {
          reject('Not online');
        }

      });




    });

  }


  async onSlideDidChange(currentSlide: number, ) {
     const marker = this.markers[currentSlide];
    this.map.panTo({lat: marker.lat, lng: marker.lng});

    const markerObj = marker.markerObj;
    this.infoWindow.setContent(marker.title);
    this.infoWindow.open(this.map, markerObj);
  }

  public addMarker(lat: number, lng: number, animation: google.maps.Animation,
                   icon: number, id: string, relative_url: string, title: string, tag: string, businessName: string, image: string, isCurrentPosition: boolean) {

    let latLng = new google.maps.LatLng(lat, lng);

    let marker = new google.maps.Marker({
      map: this.map,
      title: title,
      animation: animation,
      position: latLng,
     });

    if (icon === 1) {
      marker.setIcon({
        // url: "https://cdn3.iconfinder.com/data/icons/musthave/24/Stock%20Index%20Down.png"
        url: "assets/img/fire_red_128.webp",
        scaledSize: new google.maps.Size(30, 30)
      });
    }else if (icon === 2) {
      marker.setIcon({
        // url: "https://cdn3.iconfinder.com/data/icons/musthave/24/Stock%20Index%20Down.png"
        url: "assets/img/fire_yellow_128.webp",
        scaledSize: new google.maps.Size(40, 40)
      });
    }

    this.addInfoWindowsToMarker(marker, id, relative_url, tag, businessName, image);

    if (!isCurrentPosition)
      this.markers.push(marker);

    return marker;
  }


  setMarkers(markerList: any[]) {
    console.log("setMarkers");
   this.markers = markerList;
  }

  showMarkers() {
    console.log("showMarkers");
    this.markers.forEach(marker => {

      const markerObj = this.addMarker(marker.lat, marker.lng, marker.animation, marker.icon,
          marker.id,
          marker.relative_url,
          marker.title,
          marker.tag,
          marker.business_name,
          marker.image,
          false);
      marker.markerObj = markerObj;
    });
  }

  renderMarkers(markerList: any[]) {
    console.log("renderMarkers");
    markerList.forEach(marker => {
      const markerObj = this.addMarker(marker.lat, marker.lng, marker.animation, marker.icon,
          marker.id,
          marker.relative_url,
          marker.title,
          marker.tag,
          marker.business_name,
          marker.image,
          false);
      marker.markerObj = markerObj;
    });
  }

  addInfoWindowsToMarker(marker, id: string, relative_url: string, tag: string, businessName: string , image: string){
    let infoContent = '<div id="content" > <a style="color: inherit; text-decoration: inherit;" href="' + relative_url + '">' +
                      '<div id="contentImg" style="text-align: center" >' +
                      '<img style="width:50%;height:50%" src=" ' + image + '" />' +
                      '</div>' +
                      '<h2 style="margin-top: 5px"> ' + marker.title + '</h2>' +
                      '<p class="c-primary">' + tag + '</p>' +
                      '<p><strong>' + businessName + '</strong></p>' +
                      '</a>' +
                      '</div>';

    let infoWindow = new google.maps.InfoWindow({
      content: infoContent
    });

    marker.addListener('click', () => {
      this.closeAllInfoWindows();
      infoWindow.open(this.map, marker);
    });

    this.infoWindows.push(infoWindow);

  }

  closeAllInfoWindows(){
    for(let window of this.infoWindows){
      window.close();
    }
  }


  /* detalleProducto(id, slug = null){

    this.navCtrl.navigateForward('/anuncio/' + id + '/' + slug);
  } */

}
