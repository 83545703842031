import { Component, Input,  OnInit } from '@angular/core';
import { AdminService } from '../../../api/admin.service';
import { AlertController } from '@ionic/angular';
import { FunctionsService } from '../../../services/functions.service';
import { FormGroup, FormBuilder, Validators  } from '@angular/forms';

@Component({
  selector: 'app-account',
  templateUrl: './account.page.html',
  styleUrls: ['./account.page.scss'],
})
export class AccountPage implements OnInit {
  public accountForm: FormGroup;

  account = null;

  constructor(
    public adminService: AdminService,
    public alertController: AlertController,
    public functionsService: FunctionsService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {


    this.accountForm = this.formBuilder.group({
      'iban': ['', Validators.required]
    }, {});

    this.getAccount();
  }

  getAccount(){
    this.functionsService.showLoader();
    this.adminService.getBusinessIBAN()
    .subscribe(
      (response) => {
        if(response['success'] && response['data']['account']){
          this.account = response['data']['account'];
        }
        this.functionsService.hideLoader();
      },
      (error) => {
        console.log(error);
        this.functionsService.hideLoader();
      }
    );
  }

  submitForm(){

    if (this.accountForm.valid) {
      this.functionsService.showLoader();
      let data = {
        'iban': this.accountForm.get('iban').value
      };

      this.adminService.createUpdateBusinessIBAN(data)
          .subscribe(
              (response) => {
                this.functionsService.hideLoader();
                if (response['success']) {
                  // this.employees = response['data']['business_account'];

                  this.accountForm.setValue({
                    iban: ''
                  });

                  this.functionsService.showToast(response['data']['message']);

                  this.getAccount();
                } else {
                  this.functionsService.showDangerToast(response['data']['error_message']);
                }
              },
              (error) => {
                console.log(error);
                this.functionsService.hideLoader();
              }
          );
    }else{
      this.functionsService.showDangerToast("El IBAN es obligatorio");
    }

  }

}
