import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-producto-tarjeta',
  templateUrl: './producto-tarjeta.component.html',
  styleUrls: ['./producto-tarjeta.component.scss'],
})
export class ProductoTarjetaComponent implements OnInit {

  @Input() product_ad = {};
  @Input() is_loading = false;
  @Input() is_admin = false;
  @Input() btn_more = false;

  is_offert = false;
  is_event = false;
  is_bono = false;

  constructor(
    private router: Router,
    public navCtrl:NavController
    ) { }

  ngOnInit() {
    if(this.product_ad['type_id'] == 1){
      this.is_offert = true;
    }
    else if(this.product_ad['type_id'] == 2){
      this.is_event = true;
    }
    else if(this.product_ad['type_id'] == 3){
      this.is_bono = true;
    }
  }

  detalleProducto(id, relative_url = null){
    if(this.is_admin){
      this.navCtrl.navigateForward('/admin/config-ad/' + id);
    }else{
      this.navCtrl.navigateForward(relative_url);
    }
  }

}
