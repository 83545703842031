import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from '../api/login.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanLoad {

  constructor(private loginService:LoginService){}

  canLoad(): Observable<boolean> | Promise<boolean> | boolean {
    return this.loginService.validateToken(true);
  }
}
